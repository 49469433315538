import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import img1 from '../assets/enhance.webp';
import img2 from '../assets/bgr.webp';
import img3 from '../assets/cartoons.webp';
import img4 from '../assets/blur.webp';
import img5 from '../assets/cutout.webp';
import img6 from '../assets/passport.webp';
import video1 from '../assets/bgv.webm';
import video2 from '../assets/obr.webm';
import img7 from '../assets/pretouch.webp';
import img8 from '../assets/ss3.webp'
import img9 from '../assets/converter.webp'
import img10 from '../assets/wbg.webp'

const PhotoEditorTools = () => {
    const [activeTab, setActiveTab] = useState('Ai Powered Tools');
    const [hovered, setHovered] = useState(null);

    const aiTools = [
        { 
            name: 'Photo Enhancer & Upscaler', 
            image: img1, 
            link: '/imgenhance', 
            description: 'Automatically enhance image resolution, improving clarity and bringing out the finer details.'
        },
        { 
            name: 'Ai Background Changer', 
            image: img2, 
            link: '/background-remover',
            description: 'Take the background out of photo faster than ever. Upload an image and get a clean cutout.'
        },
        { 
            name: 'Ai Cartoon Selfie', 
            image: img3, 
            link: '/imgenhance', 
            description: 'Convert your photos in cartoon in one click.'
        },
        { 
            name: 'Ai Blur Background', 
            image: img4, 
            link: '/imgenhance', 
            description: 'Automatically blur background of photos with our Ai tool.'
        },
        { 
            name: 'Ai White Background', 
            image: img10, 
            link: '/whitebg', 
            description: 'Automatically blur background of photos with our Ai tool.'
        },
        { 
            name: 'Photo Retouch', 
            image: img7, 
            link: '/imgenhance', 
            description: 'Automatically Retouch of photos with our Ai tool.'
        },
    ];

    const photoEditorTools = [
        { 
            name: 'Ai Background Video', 
            video: video1, 
            link: '/adjust-brightness',
            description: 'Use video to convert your photo in passport photo.'
        },
        { 
            name: 'Ai Background Object', 
            video: video2, 
            link: '/adjust-brightness',
            description: 'Use video to convert your photo in passport photo.'
        },
        { 
            name: 'Face Cutout', 
            image: img5, 
            link: '/crop-tool',
            description: 'Cutout your face photos to perfection with our easy-to-use tool.'
        },
        { 
            name: 'Passport Photo Maker', 
            image: img6, 
            link: '/passport',
            description: 'Easily convert your photo in passport photo.'
        },
        { 
            name: 'Background Remover', 
            image: img8, 
            link: '/bgrm',
            description: 'Easily Remove Background of your image in one click.'
        },
        { 
            name: 'Image Converter', 
            image: img9, 
            link: '/converter',
            description: 'Easily convert your photo in passport photo.'
        },
    ];

    const categories = {
        'Ai Powered Tools': aiTools,
        'Ai Editing and Removal Tools': photoEditorTools,
    };

    const toolsToDisplay = categories[activeTab] || categories['Ai Powered Tools'];

    return (
        <div className="py-8 text-center bg-gradient-to-b from-white to-blue-100">
            <h2 className="text-3xl md:text-4xl font-bold mb-4 md:mb-6">Discover More <span className="text-blue-500">Photo Editing Tools</span></h2>
            <p className="text-gray-600 mb-6 md:mb-10">All AI image generator and design tools are right at your fingertips. Edit photos like a pro now!</p>
            <div className="flex flex-wrap justify-center mb-6 md:mb-10">
                {Object.keys(categories).map(category => (
                    <button
                        key={category}
                        className={`py-2 px-4 md:py-2 md:px-6 mx-2 mb-2 md:mb-0 rounded-full text-sm md:text-lg font-semibold transition-all duration-300 ${activeTab === category ? 'bg-black text-white' : 'bg-white border-2 border-black text-blue-500 hover:bg-black hover:text-white'}`}
                        onClick={() => setActiveTab(category)}
                    >
                        {category}
                    </button>
                ))}
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-10 max-w-6xl mx-auto px-4 md:px-8">
            {toolsToDisplay.map((tool, index) => (
    <div
        key={index}
        className="relative bg-white shadow-lg rounded-lg overflow-hidden w-full sm:max-w-xs flex flex-col justify-between mx-auto transform transition-transform duration-300 hover:scale-105"
        onMouseEnter={() => setHovered(index)}
        onMouseLeave={() => setHovered(null)}
    >
        <div>
            <div className="overflow-hidden">
                {tool.video ? (
                    <video 
                        className="w-full h-48 md:h-64 object-cover transform transition-transform duration-300 hover:scale-105"
                        autoPlay
                        loop
                        muted
                        preload="metadata"
                        playsInline
                        controlsList="nodownload"
                        onContextMenu={(e) => e.preventDefault()} // Disable right-click on video
                    >
                        <source src={tool.video} type="video/webm" />
                        Your browser does not support the video tag.
                    </video>
                ) : (
                    <img 
                        src={tool.image} 
                        alt={tool.name} 
                        className={`w-full ${tool.name === 'Face Cutout' ? 'h-48 md:h-64' : 'h-50 md:h-66'} object-cover transform transition-transform duration-300 hover:scale-105`}
                    />
                )}
            </div>
            <div className="p-4 flex-grow">
                <h3 className="text-lg md:text-xl font-semibold mb-2">{tool.name}</h3>
                <p className="text-gray-600 mb-4 text-sm md:text-base">{tool.description}</p>
            </div>
        </div>
        <div className="p-4">
            <Link
                to={tool.link}
                className="text-white bg-black hover:bg-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs md:text-sm px-3 md:px-4 py-2 text-center block"
            >
                Try Now
            </Link>
        </div>
    </div>
))}

                
            </div>
        </div>
    );
};

export default PhotoEditorTools;
