import React from "react";
import styles from './ImageShowcase.module.css';
import sampleVideo from '../assets/poster.webm';

const ImageShowcase = () => {
  return (
    <div className="bg-gradient-to-r from-blue-200 via-purple-300 to-blue-200 py-20 px-8 md:px-10 text-center">
      <div className="flex flex-col md:flex-row items-center justify-center md:justify-between max-w-5xl mx-auto">
        {/* Video Section */}
        <div className="relative w-full md:w-1/2 flex justify-center">
          <div className="relative">
            {/* Large Video with Transition and Animation */}
            <div className="relative w-full h-full overflow-hidden">
              <video
                src={sampleVideo}
                autoPlay
                loop
                muted
                playsInline
                className={`w-full h-auto object-contain rounded-lg ${styles.largeVideo}`}
                onContextMenu={(e) => e.preventDefault()} // Disable right-click on video
                controlsList="nodownload" // Prevent download option from showing on some browsers
                preload="none" // Prevent the video from preloading, which might reduce automatic download
                disablePictureInPicture // Prevent Picture-in-Picture mode
                controls={false} // Ensure no native controls are available
              />
              {/* Invisible Overlay to block interaction */}
              <div className={styles.overlay}></div>
            </div>
          </div>
        </div>

        {/* Text Section */}
        <div className="mt-8 md:mt-0 md:ml-10 w-full md:w-1/2 text-left">
          <h1 className="text-3xl md:text-4xl font-bold text-gray-800 mb-4">
            Remove Background and Create Stunning{" "}
            <span className="text-blue-600">Product Photos</span> Instantly
          </h1>
          <p className="text-gray-600 text-lg">
            Quickly isolate your image subject with Imgify background remover. You can process up to 30 files and do more editing at a time. Say goodbye to manual editing and removing backgrounds online has never been easier!
          </p>
        </div>
      </div>
    </div>
  );
};

export default ImageShowcase;
