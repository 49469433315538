import React, { useState } from 'react';
import 'tailwindcss/tailwind.css';
import rev1 from '../assets/rev2.webp';
import rev2 from '../assets/rev1.webp';
import rev3 from '../assets/rev3.webp';
import rev4 from '../assets/rev4.webp';
import rev5 from '../assets/rev5.webp';
const reviews = [
  {
    name: 'John Doe',
    role: 'Graphic Designer',
    review: 'I just used Imgify Ai to edit some photos and was pleased with the results. The site is easy to use, and the editing tools are intuitive. If you\'re looking for a user-friendly photo editor to unblur your pictures or a superb background remover, I\'d definitely recommend this site.',
    rating: 5,
    img: rev1,
  },
  {
    name: 'Emma Johnson',
    role: 'E-commerce Specialist',
    review: 'I like its AI visualization which saves my time. I change the background of an image, enhance it, and remove any object from an image. In comparison to other software, it has a powerful AI which makes my work easy. Also, it has a very easy-to-use interface.',
    rating: 5,
    img: rev2,
  },
  {
    name: 'Michael Brown',
    role: 'Music Teacher',
    review: 'Congratulations on developing such an amazing web app. I have been looking for many years for a tool to sharpen blurry images of scanned vintage sheet music made available online by libraries or sent directly to me, and (presumably with AI help) this works miraculously!',
    rating: 5,
    img: rev3,
  },
  {
    name: 'Sophia Williams',
    role: 'Photographer',
    review: 'Imgify Ai is now my go-to tool for enhancing images. The sharpness and quality improvements are remarkable, and the interface is user-friendly. It has become an essential part of my editing workflow. Congratulations on developing such an amazing web app',
    rating: 5,
    img: rev5, // Add your image here
  },
  {
    name: 'James Davis',
    role: 'Content Creator',
    review: 'I use Imgify Ai to remove backgrounds and enhance my images for social media. The results are always professional, and the tool is incredibly easy to use. Highly recommended for anyone in need of quick and effective image editing. Congratulations on developing such an amazing web app',
    rating: 5,
    img: rev4, // Add your image here
  },
];

const ReviewCard = ({ name, role, review, rating, img }) => (
  <div className="bg-white p-4 rounded-lg shadow-lg flex-none w-64 mx-2">
    {/* Review Text */}
    <p className="text-gray-800 mb-4">{review}</p>
    {/* Star Rating */}
    <div className="flex items-center mb-4">
      {[...Array(rating)].map((_, i) => (
        <svg
          key={i}
          className="w-5 h-5 text-yellow-500"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M10 15l-5.5 3 1-6-4-4 6-1L10 0l2.5 7 6 1-4 4 1 6z" />
        </svg>
      ))}
    </div>
    {/* Reviewer Info */}
    <div className="flex items-center">
      <img src={img} alt={name} className="w-12 h-12 rounded-full mr-4" />
      <div className="text-left">
        <h3 className="font-bold text-lg text-gray-800">{name}</h3>
        <p className="text-gray-500">{role}</p>
      </div>
    </div>
  </div>
);

const Reviews = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? reviews.length - 1 : prevIndex - 1
    );
  };

  const visibleReviews = [
    reviews[currentIndex],
    reviews[(currentIndex + 1) % reviews.length],
    reviews[(currentIndex + 2) % reviews.length],
  ];

  return (
    <div className="bg-gradient-to-r from-blue-200 via-purple-300 to-blue-200 py-16 px-8 relative">
      {/* Section Title */}
      <h2 className="text-4xl font-bold text-center text-gray-800 mb-12">
        What Do Our Users Say
      </h2>
      <div className="flex justify-center items-center space-x-4">
        {/* Previous Button */}
        <button
          className="absolute left-0 transform -translate-y-1/2 bg-gray-800 text-white p-6 rounded-full hover:bg-gray-700 z-10"
          onClick={prevSlide}
          style={{ fontSize: '2.5rem', lineHeight: '1', top: '50%' }}
        >
          &#8249;
        </button>
        {/* Review Cards Container */}
        <div className="flex space-x-4 overflow-hidden">
          {visibleReviews.map((review, index) => (
            <ReviewCard key={index} {...review} />
          ))}
        </div>
        {/* Next Button */}
        <button
          className="absolute right-0 transform -translate-y-1/2 bg-gray-800 text-white p-6 rounded-full hover:bg-gray-700 z-10"
          onClick={nextSlide}
          style={{ fontSize: '2.5rem', lineHeight: '1', top: '50%' }}
        >
          &#8250;
        </button>
      </div>
    </div>
  );
};

export default Reviews;
