import React, { useState, useEffect } from 'react';
import originalImage from '../assets/bga.webp'; // Image with background
import backgroundRemovedImage from '../assets/bga1.webp'; // Same image but with background removed

const Toolpp3 = () => {
    const [sliderValue, setSliderValue] = useState(50);
    const [scrolling, setScrolling] = useState(false);

    const handleMouseMove = (e) => {
        const bounds = e.currentTarget.getBoundingClientRect();
        const newValue = ((e.clientX - bounds.left) / bounds.width) * 100;
        setSliderValue(Math.max(0, Math.min(100, newValue)));
    };

    // Handle scroll event to show transparent background when scrolling
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) { // Adjust the scroll value as needed
                setScrolling(true);
            } else {
                setScrolling(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="bg-gradient-to-r from-blue-200 via-purple-300 to-blue-200 py-10 text-center">
            <div className="flex flex-col lg:flex-row items-center justify-between px-4 py-0 max-w-7xl mx-auto gap-12">
                <div
                    className="relative w-full lg:w-1/2 max-w-3xl mx-auto order-2 lg:order-1"
                    onMouseMove={handleMouseMove}
                >
                    <div className="relative w-full h-0 pb-[56.25%] overflow-hidden rounded-lg shadow-lg">
                        {/* Transparent Background when scrolling */}
                        {scrolling && (
                            <div
                                className="absolute top-0 left-0 w-full h-full bg-white"
                                style={{
                                    backgroundImage: `linear-gradient(45deg, #ccc 25%, transparent 25%),
                                                      linear-gradient(-45deg, #ccc 25%, transparent 25%),
                                                      linear-gradient(45deg, transparent 75%, #ccc 75%),
                                                      linear-gradient(-45deg, transparent 75%, #ccc 75%)`,
                                    backgroundSize: "20px 20px",
                                    backgroundPosition: "0 0, 0 10px, 10px -10px, -10px 0px",
                                }}
                            ></div>
                        )}
                        {/* Image with Background */}
                        <img
                            src={originalImage}
                            alt="Original"
                            className="absolute top-0 left-0 w-full h-full object-cover"
                            style={{
                                clipPath: `inset(0 0 0 ${sliderValue}%)`, // Reveal the original image from the right
                            }}
                        />
                        {/* Image with Background Removed */}
                        <img
                            src={backgroundRemovedImage}
                            alt="Background Removed"
                            className="absolute top-0 left-0 w-full h-full object-cover"
                            style={{
                                clipPath: `inset(0 ${100 - sliderValue}% 0 0)`, // Reveal the background-removed image from the left
                            }}
                        />
                        {/* Slider */}
                        <div
                            className="absolute top-0 bottom-0 left-0 h-full bg-white cursor-pointer"
                            style={{ left: `${sliderValue}%`, width: '2px' }}
                        >
                            <div
                                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white border border-gray-500 rounded-full w-4 h-4"
                            ></div>
                        </div>
                        <span className="absolute top-2 left-2 text-white text-lg font-bold bg-black bg-opacity-50 px-2 py-1 rounded">
                            After
                        </span>
                        <span className="absolute top-2 right-2 text-white text-lg font-bold bg-black bg-opacity-50 px-2 py-1 rounded">
                            Before
                        </span>
                    </div>
                </div>
                <div className="lg:w-1/2 w-full text-left lg:text-left mb-8 lg:mb-0 order-1 lg:order-2">
                    <h2 className="text-4xl font-extrabold text-gray-900 mb-4">Effortlessly Remove Backgrounds with Imgify AI</h2>
                    <p className="text-lg text-gray-600 max-w-2xl mb-8">
                        Say goodbye to tedious manual background removal! Imgify AI allows you to instantly remove backgrounds from any image with just a simple swipe. Perfect for creating stunning visuals and enhancing your workflow. 
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Toolpp3;
