import React from 'react';
import img1 from '../assets/post1.webp';
import img2 from '../assets/post2.webp';
import img3 from '../assets/post3.webp';
import img4 from '../assets/post4.webp';
import img5 from '../assets/post5.webp';
import img6 from '../assets/post6.webp';
const posts = [
  {
    title: 'Best Softwares for RAW Photo Editing: Comprehensive Guide',
    img: img1,
    link: 'https://imgify.net/blog/best-softwares-for-raw-photo-editing-comprehensive-guide/',
  },
  {
    title: 'Top 5 Free Web-based Online Photo Editors',
    img: img2,
    link: 'https://imgify.net/blog/top-5-free-web-based-online-photo-editors/',
  },
  {
    title: 'How to Increase JPEG Resolution: Transform Low-Quality Photos into HD',
    img: img3,
    link: 'https://imgify.net/blog/how-to-increase-jpeg-resolution-transform-low-quality-photos-into-hd/',
  },
  {
    title: 'How to Make a Blurry Picture Clear Step by Step Guide',
    img: img4,
    link: 'https://imgify.net/blog/how-to-make-a-blurry-picture-clear-step-by-step-guide/',
  },
  {
    title: 'How to turn off Auto Photo Enhancer on iPhone',
    img: img5,
    link: 'https://imgify.net/blog/how-to-turn-off-auto-photo-enhancer-on-iphone/',
  },
  {
    title: 'Best Free Online Photo Enhancer: Clear Blurry Images Online',
    img: img6,
    link: 'https://imgify.net/blog/best-free-online-photo-enhancer-clear-blurry-images-online/',
  },
];

const PostCard = ({ title, img, link }) => (
  <div className="bg-white rounded-lg shadow-lg overflow-hidden">
    <a href={link} target="_blank" rel="noopener noreferrer">
      <img src={img} alt={title} className="w-full h-48 object-cover" />
      <div className="p-4">
        <h3 className="text-lg font-bold text-gray-800">{title}</h3>
      </div>
    </a>
  </div>
);

const Posts = () => (
  <div className="bg-gradient-to-r from-blue-200 via-purple-300 to-blue-200 py-16 px-8">
    <h2 className="text-4xl font-bold text-center text-gray-800 mb-12">
      Latest Posts
    </h2>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {posts.map((post, index) => (
        <PostCard key={index} {...post} />
      ))}
    </div>
  </div>
);

export default Posts;
