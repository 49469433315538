import React from 'react';
import { Link } from 'react-router-dom';
import img from '../assets/ppp1.webp';  // Replace with your actual image path
import styles from './Ppp.module.css';  // Import the CSS module

const Ppp = () => {
    return (
        <div className="flex flex-col md:flex-row items-center bg-gradient-to-r from-blue-200 via-purple-300 to-blue-200 py-12 px-8 md:px-12">
            <div className="text-center md:text-left md:w-1/2">
                <h2 className={`text-4xl md:text-5xl font-bold mb-4 ${styles.animateText}`}>
                    <span className="text-blue-600">Enhance Details</span> to Transform Blurry Photos into Crystal Clear
                </h2>
                <p className="text-gray-600 text-lg mb-6">
                    No professional skills required! With our tool, you can effortlessly unblur and sharpen images online for free. 
                    Transform your photos from fuzzy to clear, ensuring high-resolution images for personal and business needs.
                </p>
                
            </div>
            <div className="relative mt-8 md:mt-0 md:w-1/2">
                <div className={`relative w-full h-auto ${styles.animateMotion}`}>
                    <img src={img} alt="Enhance" className="w-full h-full object-cover" /> 
                    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-between px-4 md:px-8">
                        <span className="bg-gray-800 text-white px-2 py-1 rounded-full text-xs md:text-sm">Before</span>
                        <span className="bg-blue-600 text-white px-2 py-1 rounded-full text-xs md:text-sm">After</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Ppp;
