import React from 'react';

const services = [
    { title: 'AI Background Remover', link: '/bgrm' },
    { title: 'Photo Retouch', link: '/photo-retouch' },
    { title: 'AI Blur Background', link: '/blur-background' },
    { title: 'Face Cutout', link: '/face-cutout' },
    { title: 'Image Converter', link: '/image-converter' },
    { title: 'Passport Photo Maker', link: '/passport' },
];

const Rtools = () => {
    return (
        <div className="bg-gradient-to-r from-blue-200 via-purple-300 to-blue-200 py-1 text-center">
            <h2 className="text-4xl font-bold text-blue-900 mb-4 uppercase">Related Tools</h2>
            
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-w-4xl mx-auto px-4">
                {services.map((service, index) => (
                    <a 
                        key={index} 
                        href={service.link}
                        className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transform hover:scale-105 transition-transform duration-300"
                    >
                        <h3 className="text-xl font-semibold text-gray-800">{service.title}</h3>
                    </a>
                ))}
            </div>
        </div>
    );
};

export default Rtools;
