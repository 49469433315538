import React from 'react';
import img1 from '../assets/passport2.webp'; // Import your images here
import img2 from '../assets/passport3.webp';
import img3 from '../assets/passport4.webp';

const PassportPhotoSection = () => {
  return (
    <div className='min-h-screen flex flex-col justify-center items-center bg-gradient-to-r from-blue-200 via-purple-300 to-blue-200 py-16'>
      <div className="container mx-auto px-5">
        <h2 className="text-4xl font-bold text-center mb-12 text-gray-800">Passport Photo Maker Features</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {/* First Image Box */}
          <div className="text-center bg-white p-6 rounded-lg shadow-md">
            <img src={img1} alt="UK Passport Size" className="w-full h-48 object-cover rounded-lg mb-4" />
            <h3 className="text-xl font-semibold mb-2 text-gray-700">UK Passport Photo</h3>
            <p className="text-gray-600">Create perfectly sized UK passport photos in one click.</p>
          </div>

          {/* Second Image Box */}
          <div className="text-center bg-white p-6 rounded-lg shadow-md">
            <img src={img2} alt="US Passport Size" className="w-full h-48 object-cover rounded-lg mb-4" />
            <h3 className="text-xl font-semibold mb-2 text-gray-700">US Passport Photo</h3>
            <p className="text-gray-600">Get your US passport photos in the required dimensions effortlessly.</p>
          </div>

          {/* Third Image Box */}
          <div className="text-center bg-white p-6 rounded-lg shadow-md">
            <img src={img3} alt="India Passport Size" className="w-full h-48 object-cover rounded-lg mb-4" />
            <h3 className="text-xl font-semibold mb-2 text-gray-700">India Passport Photo</h3>
            <p className="text-gray-600">Easily resize images to match Indian passport requirements.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PassportPhotoSection;
