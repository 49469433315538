import React from 'react';
import yourImage from '../assets/passport8.webp'; // Update this path to your actual image path

const Pas3 = () => {
    return (
        <div className="bg-gradient-to-r from-blue-200 via-purple-300 to-blue-200 py-10 text-center">
            <div className="flex flex-col lg:flex-row items-center justify-between px-4 py-0 max-w-7xl mx-auto gap-12">
                {/* Text Section */}
                <div className="lg:w-1/2 w-full text-left lg:text-left mb-8 lg:mb-0 order-1 lg:order-1">
                    <h4 className="text-4xl font-extrabold text-gray-900 mb-4">Create and Download Your Passport Photo</h4>
                    <p className="text-lg text-gray-600 max-w-2xl mb-0">
                        Effortlessly generate passport photos that comply with official size standards for multiple countries. Once your photo is created, you can instantly download it in the required format. Our tool streamlines the entire process—upload your image, select a country, and download your perfectly formatted passport photo, ready for submission.
                    </p>
                </div>

                {/* Image Section */}
                <div className="relative w-full lg:w-1/2 max-w-3xl mx-auto order-2 lg:order-2">
                    <div className="relative w-full h-0 pb-[56.25%] overflow-hidden rounded-lg shadow-lg">
                        <img
                            src={yourImage}
                            alt="Passport Photo Maker"
                            className="absolute top-0 left-0 w-full h-full object-cover"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pas3;
