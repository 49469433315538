import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { CiImageOn } from "react-icons/ci";
import axios from 'axios';
import Rtools from './Rtools';
import Toolpp3 from './Toolpp3';
import Reviews from './Reviews';
import imgg from '../assets/toolp5.jpg';
import Toolpp from './Toolpp';
import Toolpp2 from './Toolpp2';
import Faqs from './Faqs';
import Features from './Features';

export default function Imgenhance() {
    const [imageSrc, setImageSrc] = useState('');
    const [file, setFile] = useState(null);
    const [enhancedImage, setEnhancedImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [sliderValue, setSliderValue] = useState(50);
    const [showRightImage, setShowRightImage] = useState(true);
    const [downloadName, setDownloadName] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        let timer;
        if (loading) {
            timer = setInterval(() => {
                setElapsedTime(prevTime => prevTime + 1);
            }, 1000);
        } else {
            clearInterval(timer);
            setElapsedTime(0);
        }
        return () => clearInterval(timer);
    }, [loading]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFile(file);
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImageSrc(reader.result);
                setShowRightImage(false);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async () => {
        if (!file) {
            alert('Please upload an image first.');
            return;
        }
    
        setLoading(true);
    
        const formData = new FormData();
        formData.append('file', file);
    
        try {
            const response = await axios.post('http://127.0.0.1:5000/process-image', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            console.log('Response:', response.data); // Debugging
    
            const { image_url, download_name } = response.data;
            if (image_url) {
                setEnhancedImage(image_url);
                setDownloadName(download_name);
            } else {
                console.error('Image URL not found in response.');
            }
            setLoading(false);
        } catch (error) {
            console.error('Error processing image:', error);
            setLoading(false);
        }
    };
    
    
    

    const handleClear = () => {
        setImageSrc('');
        setFile(null);
        setEnhancedImage(null);
        setShowRightImage(true);
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = enhancedImage;
        link.setAttribute('download', downloadName || 'enhanced_image.jpg'); // Fallback name if downloadName is missing
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    
    

    const handleMouseMove = (e) => {
        const bounds = e.currentTarget.getBoundingClientRect();
        const newValue = ((e.clientX - bounds.left) / bounds.width) * 100;
        setSliderValue(Math.max(0, Math.min(100, newValue)));
    };

    return (
        <div className='min-h-screen flex flex-col overflow-hidden bg-gradient-to-r from-blue-200 via-purple-300 to-blue-200'>
            <Navbar />
            <div className='flex-grow container mx-auto px-4'>
                <h2 className="text-3xl font-bold text-center text-gray-800 mt-20">
                    AI Image Enhancer & Image Upscaler
                </h2>
                <p className="text-lg text-center text-gray-600 mt-5">
                    Effortlessly enhance and upscale your photo to get higher resolution images with one single click.
                </p>
                <div className='flex flex-col lg:flex-row justify-center items-center mt-10'>
                    <div className='w-full lg:w-1/2'>
                        <div className='w-15 h-55 shad mt-5 rounded-lg border-dotted border-2 border-gray-300 bg-white p-12 relative'>
                            <div className='w-full h-auto text-center text-gray-500'>
                                <input
                                    type="file"
                                    onChange={handleFileChange}
                                    className='hidden'
                                    id="fileInput"
                                />
                                {!imageSrc && (
                                    <label
                                        htmlFor="fileInput"
                                        className="cursor-pointer text-sm text-black"
                                    >
                                        <CiImageOn className="inline-block text-4xl mb-2 text-black" />
                                        <p className="text-lg font-semibold">Upload Image</p>
                                        <p className="text-xs text-gray-500 mt-1">Image size limit: 5MB</p>
                                    </label>
                                )}
                            </div>
                            {imageSrc && (
                                <div className='w-full h-auto'>
                                    <img
                                        src={imageSrc}
                                        alt="Selected"
                                        className='w-full rounded-lg shadow-md'
                                        style={{ height: 'auto', maxHeight: '400px' }}
                                    />
                                </div>
                            )}
                        </div>
                        {imageSrc && (
                            <div className='w-full mt-5 flex justify-between'>
                                <button
                                    className='w-1/2 mr-2 h-10 rounded-lg bg-gray-200 text-gray-800 font-medium hover:bg-gray-300'
                                    onClick={handleClear}
                                >
                                    Clear
                                </button>
                                <button
                                    className='w-1/2 ml-2 h-10 rounded-lg bg-black text-white font-medium hover:bg-gray-800'
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>
                        )}
                        {loading && (
                            <div className='mt-10 text-center'>
                                <p className='text-lg'>Processing Time: {elapsedTime} seconds</p>
                            </div>
                        )}
                    </div>

                    {showRightImage && (
                        <div className='w-full lg:w-1/2 flex items-center justify-center mt-5 lg:mt-0 lg:pl-10'>
                            <div
                                className="relative w-full h-64 lg:h-72 overflow-hidden rounded-lg shadow-lg"
                                onMouseMove={handleMouseMove}
                            >
                                <img
                                    src={imgg}
                                    alt="Enhancer"
                                    className="absolute top-0 left-0 w-full h-full object-cover"
                                    style={{ filter: `blur(2px)` }}
                                />
                                <img
                                    src={imgg}
                                    alt="Enhancer"
                                    className="absolute top-0 left-0 w-full h-full object-cover"
                                    style={{
                                        clipPath: `inset(0 0 0 ${sliderValue}%)`
                                    }}
                                />
                                <div
                                    className="absolute top-0 bottom-0 left-0 h-full bg-white cursor-pointer"
                                    style={{ left: `${sliderValue}%`, width: '2px' }}
                                >
                                    <div
                                        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white border border-gray-500 rounded-full w-4 h-4"
                                    ></div>
                                </div>
                                <span className="absolute top-2 left-2 text-white text-lg font-bold bg-black bg-opacity-50 px-2 rounded-lg">
                                    Before
                                </span>
                                <span className="absolute top-2 right-2 text-white text-lg font-bold bg-black bg-opacity-50 px-2 rounded-lg">
                                    After
                                </span>
                            </div>
                        </div>
                    )}
                </div>

                {enhancedImage && (
    <div className='mt-10 text-center'>
        <p className='text-lg font-semibold'>Enhanced Image</p>
        <div className='w-full flex justify-center mt-5'>
            <img
                src={enhancedImage}
                alt="Enhanced"
                className='rounded-lg shadow-md'
                style={{ width: '100%', maxWidth: '500px', height: 'auto' }}
            />
        </div>
        <button
            className='mt-5 h-10 w-full rounded-lg bg-black text-white font-medium hover:bg-gray-800'
            onClick={handleDownload}
        >
            Download Image
        </button>
    </div>
)}

            </div>
            <Rtools />
            <Toolpp />
            <Toolpp2 />
            <Features />
            <Faqs />
            <Reviews />
            <Footer />
        </div>
    );
}
