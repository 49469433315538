import React, { useState } from 'react';
import yourImage from '../assets/style2.jpg'; // Update this path to your actual image path
import style from './Toolpp.module.css'
const Toolpp = () => {
    const [sliderValue, setSliderValue] = useState(50);

    const handleMouseMove = (e) => {
        const bounds = e.currentTarget.getBoundingClientRect();
        const newValue = ((e.clientX - bounds.left) / bounds.width) * 100;
        setSliderValue(Math.max(0, Math.min(100, newValue)));
    };

    return (
        <div className="bg-gradient-to-r from-blue-200 via-purple-300 to-blue-200 py-10 text-center">
            <div className="flex flex-col lg:flex-row items-center justify-between px-4 py-0 max-w-7xl mx-auto gap-12">
                <div className="lg:w-1/2 w-full text-left lg:text-center mb-8 lg:mb-0">
                    <h2 className="text-4xl font-extrabold text-gray-900 mb-4">Pro-Level AI Photo Enhancer</h2>
                    <p className="text-lg text-gray-600 max-w-2xl mb-8">
                        Using our AI image enhancer tool, you can now easily unblur images online with one click. 
                        Powered by AI enhancement algorithms, our photo enhancer helps to perfect and sharpen photos in no time. 
                        Enhance images to twice or four times total pixel count for a brilliant result.
                    </p>
                </div>
                <div
                    className="relative w-full lg:w-1/2 max-w-3xl mx-auto"
                    onMouseMove={handleMouseMove}
                >
                    <div className="relative w-full h-0 pb-[56.25%] overflow-hidden rounded-lg shadow-lg">
                        <img
                            src={yourImage}
                            alt="Enhancer"
                            className="absolute top-0 left-0 w-full h-full object-cover animate-motion"
                            style={{ filter: `blur(2px)` }}
                        />
                        <img
                            src={yourImage}
                            alt="Enhancer"
                            className="absolute top-0 left-0 w-full h-full object-cover"
                            style={{
                                clipPath: `inset(0 0 0 ${sliderValue}%)`
                            }}
                        />
                        <div
                            className="absolute top-0 bottom-0 left-0 h-full bg-white cursor-pointer"
                            style={{ left: `${sliderValue}%`, width: '2px' }}
                        >
                            <div
                                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white border border-gray-500 rounded-full w-4 h-4"
                            ></div>
                        </div>
                        <span className="absolute top-2 left-2 text-white text-lg font-bold bg-black bg-opacity-50 px-2 py-1 rounded">
                            Before
                        </span>
                        <span className="absolute top-2 right-2 text-white text-lg font-bold bg-black bg-opacity-50 px-2 py-1 rounded">
                            After
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Toolpp;
