import React, { useEffect, useState } from 'react';
import { loadAuth2 } from 'gapi-script';
import { GoogleLogin } from 'react-google-login';

export default function AuthPopup({ onClose, setUser }) {
  const [isLoginMode, setIsLoginMode] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const initClient = async () => {
      try {
        await loadAuth2(
          '1008959930364-ccpa3c5soqlhibvin6ru12ooi8s2gj49.apps.googleusercontent.com',
          ''
        );
      } catch (error) {
        console.error('Error loading Google API:', error);
      }
    };

    initClient();
  }, []);

  const toggleMode = () => {
    setIsLoginMode(!isLoginMode);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const endpoint = isLoginMode
      ? 'https://colourscrafters.com/api/login.php'
      : 'https://colourscrafters.com/api/register.php';
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.text();
      if (data.includes('success')) {
        console.log('User logged in successfully:', email);
        const userData = {
          email,
          imageCredits: "unlimited",
          videoCredits: "unlimited",
        };
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
      } else {
        console.error('Login/Register error:', data);
        alert(data);
      }
    } catch (error) {
      console.error('Login/Register error:', error);
      alert('An error occurred. Please try again.');
    }

    onClose();
  };

  const handleGoogleSuccess = async (googleData) => {
    try {
      const auth2 = window.gapi.auth2.getAuthInstance();
      const profile = auth2.currentUser.get().getBasicProfile();
      const email = profile.getEmail();
      console.log('Google Login Success:', email);
      const userData = {
        email,
        imageCredits: "unlimited",
        videoCredits: "unlimited",
      };
      setUser(userData);
      localStorage.setItem('user', JSON.stringify(userData)); // Store user data in local storage
      onClose();
    } catch (error) {
      console.error('Google login error:', error);
      alert('An error occurred during Google login.');
    }
  };

  const handleGoogleFailure = (response) => {
    console.error('Google Login Failed:', response);
    alert('Google Login Failed. Please try again.');
  };

  return (
    <div>
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[112]">
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold">{isLoginMode ? 'Log in' : 'Sign up'}</h2>
            <button onClick={onClose} className="text-gray-700 hover:text-gray-900">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                id="email"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <input
                type="password"
                id="password"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white rounded-md py-2 font-semibold hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-500 focus:ring-opacity-50"
            >
              {isLoginMode ? 'Log in' : 'Sign up'}
            </button>
          </form>
          <div className="my-4 flex justify-center items-center">
            <GoogleLogin
              clientId="1008959930364-ccpa3c5soqlhibvin6ru12ooi8s2gj49.apps.googleusercontent.com"
              buttonText="Login with Google"
              onSuccess={handleGoogleSuccess}
              onFailure={handleGoogleFailure}
              cookiePolicy={'single_host_origin'}
            />
          </div>
          <div className="text-center">
            <p className="text-sm">
              {isLoginMode ? "Don't have an account?" : 'Already have an account?'}{' '}
              <button
                onClick={toggleMode}
                className="text-blue-500 hover:underline focus:outline-none"
              >
                {isLoginMode ? 'Sign up' : 'Log in'}
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
