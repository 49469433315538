import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { CiImageOn } from "react-icons/ci";
import axios from 'axios';
import Toolpp3 from './Toolpp3';
import Rtools from './Rtools';
import Features from './Features';
import Faqs from './Faqs';
import Reviews from './Reviews';
import Bg1 from './Bg1';
import Bg2 from './Bg2';
import demoVideo from '../assets/bgr.webm';
export default function Bgrm() {
    const [imageSrc, setImageSrc] = useState('');
    const [file, setFile] = useState(null);
    const [enhancedImage, setEnhancedImage] = useState(null); 
    const [removedBgImage, setRemovedBgImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [backgroundColor, setBackgroundColor] = useState('#ffffff');
    const [useBackgroundColor, setUseBackgroundColor] = useState(false);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [showButtons, setShowButtons] = useState(false);
    const [backgroundApplied, setBackgroundApplied] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Timer logic to measure elapsed time
    useEffect(() => {
        let timer;
        if (loading) {
            timer = setInterval(() => {
                setElapsedTime(prevTime => prevTime + 1);
            }, 1000);
        } else {
            clearInterval(timer);
            setElapsedTime(0);
        }
        return () => clearInterval(timer);
    }, [loading]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFile(file);
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async () => {
        if (!file) {
            alert('Please upload an image first.');
            return;
        }

        setLoading(true);

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post('http://127.0.0.1:5000/remove-background', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const { image_url } = response.data;
            if (image_url) {
                setEnhancedImage(image_url);
                setRemovedBgImage(image_url); 
                setImageSrc(image_url); 
            }
            setLoading(false);  // Stop the timer
        } catch (error) {
            console.error('Error processing image:', error);
            setLoading(false);  // Stop the timer on error
        }
    };

    const handleBackgroundColorSubmit = async () => {
        try {
            const response = await axios.post('http://127.0.0.1:5000/apply-background-color', {
                filename: removedBgImage.split('/').pop(),
                background_color: backgroundColor,
            });
            
            const updatedImageUrl = response.data.image_url;
            setEnhancedImage(updatedImageUrl);
            setImageSrc(updatedImageUrl); 
            setBackgroundApplied(true); 
        } catch (error) {
            console.error('Error applying background color:', error);
        }
    };

    const handleBackgroundImageSubmit = async (bgFile) => {
        const formData = new FormData();
        formData.append('file', bgFile);
        formData.append('filename', removedBgImage.split('/').pop());

        try {
            const response = await axios.post('http://127.0.0.1:5000/apply-background-image', formData);
            const updatedImageUrl = response.data.image_url;
            setEnhancedImage(updatedImageUrl);
            setImageSrc(updatedImageUrl); 
            setBackgroundApplied(true); 
        } catch (error) {
            console.error('Error applying background image:', error);
        }
    };

    const handleClear = () => {
        setBackgroundImage(null);
        setUseBackgroundColor(false); 
        setEnhancedImage(removedBgImage); 
        setImageSrc(removedBgImage); 
        setBackgroundApplied(false); 
        setShowButtons(false); 
    };

    const handleDone = () => {
        if (backgroundImage) {
            handleBackgroundImageSubmit(backgroundImage);
        } else if (useBackgroundColor) {
            handleBackgroundColorSubmit();
        }
        setShowButtons(false);
    };

    const handleDownload = () => {
        if (enhancedImage) {
            const link = document.createElement('a');
            link.href = enhancedImage;
            link.download = backgroundApplied ? 'image_with_background.jpg' : 'background_removed_image.jpg';
            link.click();
        }
    };

    return (
        <div className='min-h-screen flex flex-col overflow-hidden bg-gradient-to-r from-blue-200 via-purple-300 to-blue-200'>
            <Navbar />
            <div className='flex-grow container mx-auto px-4'>
                <h2 className="text-3xl font-bold text-center text-gray-800 mt-20">
                    AI Image Background Remover
                </h2>
                <p className="text-lg text-center text-gray-600 mt-5">
                    Effortlessly remove background of your photo with one single click.
                </p>
                <div className='flex flex-col lg:flex-row justify-center items-center mt-10'>
                    <div className='w-full lg:w-1/2'>
                        <div className='w-15 h-55 shad mt-5 rounded-lg border-dotted border-2 border-gray-300 bg-white p-12 relative'>
                            <div className='w-full h-auto text-center text-gray-500'>
                                <input
                                    type="file"
                                    onChange={handleFileChange}
                                    className='hidden'
                                    id="fileInput"
                                />
                                {!imageSrc && (
                                    <label
                                        htmlFor="fileInput"
                                        className="cursor-pointer text-sm text-black"
                                    >
                                        <CiImageOn className="inline-block text-4xl mb-2 text-black" />
                                        <p className="text-lg font-semibold">Upload Image</p>
                                        <p className="text-xs text-gray-500 mt-1">Image size limit: 5MB</p>
                                    </label>
                                )}
                                {imageSrc && (
                                    <img
                                        src={imageSrc}
                                        alt="Selected"
                                        className='w-full rounded-lg shadow-md transition-opacity duration-500 ease-in-out'
                                        style={{ height: 'auto', maxHeight: '400px' }}
                                    />
                                )}
                            </div>
                        </div>
                        {imageSrc && !enhancedImage && (
                            <div className='w-full mt-5 flex justify-between'>
                                <button
                                    className='w-1/2 mr-2 h-10 rounded-lg bg-gray-200 text-gray-800 font-medium hover:bg-gray-300'
                                    onClick={handleClear}
                                >
                                    Clear
                                </button>
                                <button
                                    className='w-1/2 ml-2 h-10 rounded-lg bg-black text-white font-medium hover:bg-gray-800'
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>
                        )}
                    </div>

                    {!imageSrc && (
                        <div className='w-full lg:w-1/2 lg:pl-10'>
                           <video
    autoPlay
    loop
    muted
    className='rounded-lg shadow-md'
    style={{ width: '100%', maxHeight: '400px', objectFit: 'cover' }}
>
    <source src={demoVideo} type="video/webm" />
    Your browser does not support the video tag.
</video>

                        </div>
                    )}

                    {enhancedImage && (
                        <div className='w-full lg:w-1/2 flex flex-col items-center mt-5 lg:mt-0 lg:pl-10'>
                            <p className='text-lg font-semibold mb-3'>Choose Background Options</p>
                            <div className='flex items-center mb-3'>
                                <input
                                    type='checkbox'
                                    checked={useBackgroundColor}
                                    onChange={(e) => { 
                                        setUseBackgroundColor(e.target.checked); 
                                        setShowButtons(true);
                                    }}
                                    className='mr-2'
                                />
                                <p className='text-lg'>Choose Background Color</p>
                                <input
                                    type='color'
                                    value={backgroundColor}
                                    onChange={(e) => {
                                        setBackgroundColor(e.target.value);
                                        setShowButtons(true);
                                    }}
                                    className='ml-2 w-12 h-12 cursor-pointer transition-colors duration-500 ease-in-out'
                                    disabled={!useBackgroundColor}
                                />
                            </div>
                            <div className='mb-3'>
                                <p className='text-lg'>Upload Background Image</p>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => {
                                        if (e.target.files[0]) {
                                            setBackgroundImage(e.target.files[0]);
                                            setShowButtons(true);
                                        }
                                    }}
                                    className="mt-2"
                                />
                                <button
                                    className='mt-2 w-full h-10 rounded-lg bg-gray-200 text-gray-800 font-medium hover:bg-gray-300'
                                    onClick={handleClear}
                                >
                                    Clear
                                </button>
                            </div>
                            {showButtons && (
                                <div className='w-full flex justify-between'>
                                    <button
                                        className='w-1/2 h-10 rounded-lg bg-black text-white font-medium hover:bg-gray-800 mr-2'
                                        onClick={handleDone}
                                    >
                                        Done
                                    </button>
                                    <button
                                        className='w-1/2 h-10 rounded-lg bg-gray-200 text-gray-800 font-medium hover:bg-gray-300 ml-2'
                                        onClick={handleClear}
                                    >
                                        Clear
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {loading && (
                    <div className="text-center text-lg font-semibold text-gray-800 mt-5">
                        Processing... Elapsed Time: {elapsedTime} seconds
                    </div>
                )}
                {enhancedImage && (
                    <div className='w-full flex justify-center mt-8'>
                        <button
                            className='w-64 h-12 rounded-lg bg-green-500 text-white text-xl font-semibold hover:bg-green-600'
                            onClick={handleDownload}
                        >
                            Download Image
                        </button>
                    </div>
                )}
            </div>
            <Rtools/>
            <Toolpp3/>
            <Bg1/>
            <Bg2/>
            <Features/>
            <Faqs/>
            <Reviews/>
            <Footer />
        </div>
    );
}
