import React from 'react';

const Faqs = () => {
    const faqData = [
        {
            question: "What is Imgify AI?",
            answer: "Imgify AI is an advanced tool that enhances, upscales, and sharpens your images using artificial intelligence, delivering high-quality results effortlessly."
        },
        {
            question: "How does Imgify AI work?",
            answer: "Imgify AI uses state-of-the-art algorithms to process your images. Simply upload an image, and our AI will automatically enhance and upscale it for better quality."
        },
        {
            question: "Is there a file size limit for uploads?",
            answer: "Yes, we currently support images up to 10MB. For larger images, consider reducing the file size before uploading."
        },
        {
            question: "Is my data safe with Imgify AI?",
            answer: "Absolutely. We prioritize your privacy and ensure that all uploaded images are processed securely and are not stored on our servers after processing."
        },
        {
            question: "Can I download the enhanced images?",
            answer: "Yes, after processing, you can easily download the enhanced images with a single click."
        },
        {
            question: "Do I need to create an account to use Imgify AI?",
            answer: "No, Imgify AI is designed to be user-friendly, allowing you to enhance images without needing to create an account."
        }
    ];

    return (
        
        <div className="bg-gradient-to-r from-blue-200 via-purple-300 to-blue-200 py-12 text-center">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-center text-gray-800 mb-12">Frequently Asked Questions</h2>
                <div className="space-y-6">
                    {faqData.map((faq, index) => (
                        <div key={index} className="faq-item border-b pb-4">
                            <h3 className="text-xl font-semibold text-gray-800">{faq.question}</h3>
                            <p className="text-gray-600 mt-2">{faq.answer}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Faqs;
