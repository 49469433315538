import React from 'react';
import { FaFacebookF, FaInstagram, FaYoutube, FaTiktok } from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Make sure you have react-router-dom installed
import logo from '../assets/Logo.webp'; // Correct path to the logo file

export default function Footer() {
    return (
        <footer className="bg-black text-white py-10">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap justify-between">
                    <div className="w-full md:w-1/4 mb-8 md:mb-0">
                        <Link to="/">
                            <div className="p-1 bg-white rounded-full inline-block"> {/* Adjusted padding to make the box smaller */}
                                <img src={logo} alt="Logo" className="h-10" /> {/* Add your logo image path */}
                            </div>
                        </Link>
                        <div className="flex space-x-4 mt-4">
                            <a href="#" className="text-white"><FaFacebookF size={20} /></a>
                            <a href="#" className="text-white"><FaInstagram size={20} /></a>
                            <a href="#" className="text-white"><FaYoutube size={20} /></a>
                            <a href="#" className="text-white"><FaTiktok size={20} /></a>
                        </div>
                        <div className="mt-4">
                            <label htmlFor="language" className="text-gray-400">Language:</label>
                            <select id="language" className="bg-black text-white ml-2 border-none outline-none">
                                <option value="english">English</option>
                                {/* Add more languages here */}
                            </select>
                        </div>
                    </div>
                    <div className="w-full md:w-1/4 mb-8 md:mb-0">
                        <h4 className="text-xl font-bold mb-4">AI Powered Tools</h4>
                        <ul className="text-lg">
                            <li><Link to="/ai-background" className="text-gray-400 hover:text-white">AI Background Changer</Link></li>
                            <li><Link to="/ai-art-generator" className="text-gray-400 hover:text-white">Photo Enhancer & Upscaler</Link></li>
                            <li><Link to="/cartoon" className="text-gray-400 hover:text-white">AI Cartoon Selfie</Link></li>
                            <li><Link to="/blurbg" className="text-gray-400 hover:text-white">AI Blur Background</Link></li>
                        </ul>
                    </div>
                    <div className="w-full md:w-1/4 mb-8 md:mb-0">
                        <h4 className="text-xl font-bold mb-4">AI Editing Tools</h4>
                        <ul className="text-lg">
                            <li><Link to="/robjects" className="text-gray-400 hover:text-white">Remove Unwanted Objects</Link></li>
                            <li><Link to="/videobg" className="text-gray-400 hover:text-white">Remove Video Background</Link></li>
                            <li><Link to="/picbg" className="text-gray-400 hover:text-white">Remove Photo Background</Link></li>
                            <li><Link to="/passport" className="text-gray-400 hover:text-white">Passport Photo Maker</Link></li>
                            <li><Link to="/facecut" className="text-gray-400 hover:text-white">Face Cutout</Link></li>
                            
                        </ul>
                    </div>
                    <div className="w-full md:w-1/4 mb-8 md:mb-0">
                        <h4 className="text-xl font-bold mb-4">Help & FAQs</h4>
                        <ul className="text-lg">
                            <li><Link to="/faq" className="text-gray-400 hover:text-white">FAQ</Link></li>
                            
                            <li><Link to="/contact" className="text-gray-400 hover:text-white">Contact Us</Link></li>
                            <li><Link to="/blog" className="text-gray-400 hover:text-white">Blogs</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="mt-10 text-center text-gray-400 text-lg">
                    <p>Copyright © 2024 Imgify All Rights Reserved.</p>
                    <div className="flex justify-center space-x-4 mt-2">
                        <Link to="/terms" className="hover:text-white">Terms</Link>
                        <Link to="/privacy" className="hover:text-white">Privacy</Link>
                        <Link to="/cookies-policy" className="hover:text-white">Cookies Policy</Link>
                        <Link to="/adminb" className="hover:text-white">License Agreement</Link>
                    </div>
                </div>
            </div>
        </footer>
    );
}
