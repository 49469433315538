import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './Components/Home'
import Login from './Components/Login'
import SignUp from './Components/SignUp'
import Contact from './Components/Contact'
import Blog from './Components/Blog'
import OutputComponent from './Components/OutputComponent'
import Imgenhance from './Components/Imgenhance'
import Bgrm from './Components/Bgrm'
import Passport from './Components/Passport'

import ImageHistory from './Components/ImageHistory'
import Dashboard from './Components/Dashboard'
export default function App() {
  return (
    <div className='w-[100%] relative h-auto'>
      <BrowserRouter basename="/">
        <Routes>
          <Route index element={<Home />} />
         
       
          <Route path='Contact' element={<Contact />} />
          <Route path='Blog' element={<Blog />} />
          <Route path='Imgenhance' element={<Imgenhance />} />
          <Route path='OutputComponent' element={<OutputComponent />} />
       
        <Route path='Bgrm' element={<Bgrm/>}/>
        <Route path='Passport' element={<Passport/>}/>
         
          <Route path='image-history' element={<ImageHistory />} />
          <Route path='Dashboard' element={<Dashboard/>} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}
