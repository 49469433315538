import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import axios from 'axios';

export default function UserBlog() {
    const [blog, setBlog] = useState([]);

    useEffect(() => {
        axios.get('/api/blogs')
            .then(response => setBlog(response.data))
            .catch(error => console.log(error));
    }, []);

    return (
        <div className="min-h-auto bg-gradient-to-b from-blue-200 to-yellow-100">
            <Navbar />
            <div className='h-auto w-full mt-[65px] pt-[40px] flex justify-center items-center flex-col py-6 px-4'>
                <div className='w-full max-w-[1250px] mx-auto flex flex-wrap gap-4 justify-between'>
                    {blog.map(item => (
                        <div key={item._id} className='w-full sm:w-[48%] lg:w-[32%] h-auto bg-white flex flex-col'>
                            <div className='w-full h-[60%] bg-red-300'>
                                <img className='w-full h-full object-cover' src={item.image || "default_image_url"} alt={item.title} />
                            </div>
                            <div className='p-4'>
                                <p className='text-lg sm:text-xl font-semibold mt-2'>{item.title}</p>
                                <h3 className='text-xs sm:text-sm font-medium text-[#6E7976] mt-2'>{item.date}</h3>
                                <p className='text-sm mt-2'>{item.content}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
