import React, {  useState, useEffect } from 'react';
import Footer from './Footer';
import Banner from './Banner';

export default function Dashboard() {
  const [user, setUser] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    // Fetch user data from the backend
    fetch('https://colourscrafters.com/api/getUserData.php', {
      credentials: 'include', // This sends cookies along with the request
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setUser(data);
          setShowSuccessMessage(true);
          setTimeout(() => setShowSuccessMessage(false), 3000);
        }
      })
      .catch((error) => console.error('Error fetching user data:', error));
  }, []);

  if (!user) {
    // If no user data is available, render a login prompt
    return (
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold">Dashboard</h1>
        <p>Please log in to view your dashboard.</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold">Dashboard</h1>

      {showSuccessMessage && (
        <div className="mt-4 p-4 bg-green-100 border border-green-400 text-green-700 rounded">
          Logged in successfully!
        </div>
      )}

      <Banner />

      <div className="mt-6 p-6 bg-white shadow-md rounded-lg">
        <div className="flex items-center">
          <img
            src={user.profile_picture || '/default-profile.png'}
            alt="Profile"
            className="w-20 h-20 rounded-full mr-4"
          />
          <div>
            <h2 className="text-2xl font-bold">{user.name}</h2>
            <p className="text-gray-600">{user.email}</p>
          </div>
        </div>
        <div className="mt-4">
          <p><strong>Member since:</strong> {new Date(user.join_date).toLocaleDateString()}</p>
          <p><strong>Last login:</strong> {new Date(user.last_login).toLocaleString()}</p>
        </div>
      </div>

      <Footer />
    </div>
  );
}
