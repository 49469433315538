import React from 'react';

const features = [
  {
    title: 'Free to Use',
    description: 'Enjoy all the powerful photo editing tools at no cost. Edit unlimited photos without spending a dime.',
  },
  {
    title: 'Unlimited Photo Editing',
    description: 'No limits, no restrictions. Edit as many photos as you want, anytime you want.',
  },
  {
    title: 'AI-Powered Tools',
    description: 'Enhance, retouch, and transform your photos with cutting-edge AI technology, making edits quick and easy.',
  },
];

const Features = () => {
  return (
    <div className="py-16 bg-gradient-to-r from-blue-200 via-purple-300 to-blue-200 px-8">
      <h2 className="text-4xl font-bold text-center text-gray-800 mb-12">
        Why Choose Imgify AI?
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {features.map((feature, index) => (
          <div key={index} className="bg-white rounded-lg shadow-lg p-6 text-center">
            <h3 className="text-2xl font-semibold text-gray-800 mb-4">{feature.title}</h3>
            <p className="text-gray-600">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;
