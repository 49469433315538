import React from 'react';
import 'tailwindcss/tailwind.css';
import { Link } from 'react-router-dom';
import './Banner.css'; // Import the CSS file

const Banner = () => {
    return (
        <div className="bg-gradient-to-r from-blue-200 via-purple-300 to-blue-200 py-20 text-center">
            <h1 className="text-5xl mt-8 mb-8 font-bold text-black">
                The <span className="animate-charcter">Imgify AI</span> Photo Editor
            </h1>
            <p className="text-black mt-8 mb-8 text-lg">
                Your all-in-one free AI photo editor. Create professional photos and bring your ideas to life effortlessly.
            </p>
            <Link to="/imgenhance">
                <button className="bg-black text-white py-3 px-6 rounded-full text-lg font-semibold hover:bg-black">
                    Get Started for Free
                </button>
            </Link>
        </div>
    );
};

export default Banner;
