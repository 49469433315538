import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'tailwindcss/tailwind.css';

const ImageHistory = () => {
  const [history, setHistory] = useState([]);

  useEffect(() => {
    const fetchImageHistory = async () => {
      try {
        const response = await axios.get('http://localhost:5000/image-history', { withCredentials: true });
        setHistory(response.data);
      } catch (error) {
        console.error('Error fetching image history:', error);
      }
    };
    fetchImageHistory();
  }, []);

  return (
    <div className="bg-gradient-to-r from-blue-200 via-purple-300 to-blue-200 min-h-screen p-8">
      <h1 className="text-4xl font-bold text-gray-800 mb-8 text-center">Image History</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {history.map((image, index) => (
          <div key={index} className="bg-white p-4 rounded-lg shadow-lg">
            <img src={`http://localhost:5000/uploads/${image.enhanced_image}`} alt={`History ${index}`} className="w-full h-auto mb-4 rounded-lg" />
            <p className="text-gray-700">Original Image: {image.original_image}</p>
            <p className="text-gray-700">Enhanced Image: {image.enhanced_image}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageHistory;
