import React from 'react';
import yourImage from '../assets/bg2.WEBP'; // Update this path to your actual image path

const Bg2 = () => {
    return (
        <div className="bg-gradient-to-r from-blue-200 via-purple-300 to-blue-200 py-10 text-center">
            <div className="flex flex-col lg:flex-row items-center justify-between px-4 py-0 max-w-7xl mx-auto gap-12">
                <div className="relative w-full lg:w-1/2 max-w-3xl mx-auto order-2 lg:order-1">
                    <div className="relative w-full h-0 pb-[56.25%] overflow-hidden rounded-lg shadow-lg">
                        <img
                            src={yourImage}
                            alt="Background Features"
                            className="absolute top-0 left-0 w-full h-full object-cover"
                        />
                    </div>
                </div>
                <div className="lg:w-1/2 w-full text-left lg:text-left mb-8 lg:mb-0 order-1 lg:order-2">
                    <h2 className="text-4xl font-extrabold text-gray-900 mb-0">Perfect Your Images with Imgify</h2>
                    <p className="text-lg text-gray-600 max-w-2xl mb-0">
                        Unlock the full potential of your photos with Imgify. Our advanced AI tools not only remove backgrounds effortlessly but also allow you to change background colors with ease. Whether you're looking to isolate subjects or enhance your images with new backgrounds, Imgify provides stunning results quickly and seamlessly. Transform your visuals and make every detail stand out.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Bg2;
