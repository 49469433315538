import React from 'react';
import yourImage from '../assets/bg1.WEBP'; // Update this path to your actual image path

const Bg1 = () => {
    return (
        <div className="bg-gradient-to-r from-blue-200 via-purple-300 to-blue-200 py-10 text-center">
            <div className="flex flex-col lg:flex-row items-center justify-between px-4 py-0 max-w-7xl mx-auto gap-12">
                <div className="lg:w-1/2 w-full text-left lg:text-left mb-8 lg:mb-0 order-1 lg:order-1">
                    <h2 className="text-4xl font-extrabold text-gray-900 mb-4">Remove Backgrounds Instantly with Imgify</h2>
                    <p className="text-lg text-gray-600 max-w-2xl mb-0">
                        Experience effortless background removal with Imgify. Our advanced AI technology allows you to remove backgrounds from images seamlessly, preserving the quality and detail of your subject. Whether for professional use or personal projects, Imgify makes background removal quick and easy, giving you flawless results every time.
                    </p>
                </div>
                <div className="relative w-full lg:w-1/2 max-w-3xl mx-auto order-2 lg:order-2">
                    <div className="relative w-full h-0 pb-[56.25%] overflow-hidden rounded-lg shadow-lg">
                        <img
                            src={yourImage}
                            alt="Background Remover"
                            className="absolute top-0 left-0 w-full h-full object-cover"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Bg1;
