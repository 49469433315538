import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { CiImageOn } from "react-icons/ci";
import axios from 'axios';
import Rtools from './Rtools';
import Toolpp from './Toolpp';
import Toolpp2 from './Toolpp2';
import Features from './Features';
import Faqs from './Faqs';
import Reviews from './Reviews';
import imgg from '../assets/passport1.webp';
import ThreePhotoSection from './ThreePhotoSection';
import Pas1 from './Pas1';
import Pas2 from './Pas2';
import Pas3 from './Pas3';
// List of country-specific passport sizes (in pixels at 300 DPI)
const passportSizes = {
    'Common Size 600*600': {width: 600, height: 600},
    'United Kingdom': { width: 413, height: 531 },
    'United States': { width: 600, height: 600 },
    'Canada': { width: 413, height: 531 },
    'Australia': { width: 413, height: 531 },
    'India': { width: 413, height: 413 },
    'Albania Passport': {width: 472, height: 590},
'Brazil Passport': {width: 590, height: 826},
'China Passport': {width: 389, height: 566},
'Denmark Passport': {width: 413, height: 531},
'Estonia Passport': {width: 472, height: 590},
'Europe Passport': {width: 413, height: 531},
'Iran Passport': {width: 354, height: 472},
'Japan Passport': {width: 413, height: 531},
'Korea Passport': {width: 413, height: 531},
'Pakistan Passport': {width: 600, height: 600},
'Spain Passport': {width: 354, height: 472},
'Taiwan Passport': {width: 600, height: 600},
'Turkey Passport': {width: 590, height: 708},

    // Add more countries as needed
};

export default function Imgenhance() {
    const [imageSrc, setImageSrc] = useState('');
    const [file, setFile] = useState(null);
    const [enhancedImage, setEnhancedImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [selectedCountry, setSelectedCountry] = useState('United Kingdom'); // Default country
    const [downloadName, setDownloadName] = useState('');
    const [sliderValue, setSliderValue] = useState(50);
    const [showRightImage, setShowRightImage] = useState(true); // Show/hide right image

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        let timer;
        if (loading) {
            timer = setInterval(() => {
                setElapsedTime(prevTime => prevTime + 1);
            }, 1000);
        } else {
            clearInterval(timer);
            setElapsedTime(0);
        }
        return () => clearInterval(timer);
    }, [loading]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFile(file);
        setShowRightImage(false); // Hide right image on upload
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async () => {
        if (!file) {
            alert('Please upload an image first.');
            return;
        }

        setLoading(true);

        const formData = new FormData();
        formData.append('file', file);
        formData.append('country', selectedCountry); // Send selected country to backend

        try {
            const response = await axios.post('http://127.0.0.1:5000/convert-passport-photo', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const { image_url, download_name } = response.data;
            if (image_url) {
                setEnhancedImage(image_url);
                setDownloadName(download_name);
            } else {
                console.error('Image URL not found in response.');
            }
            setLoading(false);
        } catch (error) {
            console.error('Error processing image:', error);
            setLoading(false);
        }
    };

    const handleClear = () => {
        setImageSrc('');
        setFile(null);
        setEnhancedImage(null);
        setShowRightImage(true); // Show right image again on clear
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = enhancedImage;
        link.setAttribute('download', downloadName || 'enhanced_image.jpg');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className='min-h-screen flex flex-col overflow-hidden bg-gradient-to-r from-blue-200 via-purple-300 to-blue-200'>
            <Navbar />
            <div className='flex-grow container mx-auto px-4'>
                <h2 className="text-3xl font-bold text-center text-gray-800 mt-20">
                    AI Passport Photo Maker
                </h2>
                <p className="text-lg text-center text-gray-600 mt-5">
                    Effortlessly convert your simple images to passport size photos with one single click.
                </p>
                <div className='flex flex-col lg:flex-row justify-center items-center mt-10'>
                    <div className='w-full lg:w-1/2 flex flex-col items-center'>
                        <div className='w-full shad mt-5 rounded-lg border-dotted border-2 border-gray-300 bg-white p-12 relative'>
                            <div className='w-full h-auto text-center text-gray-500'>
                                <input
                                    type="file"
                                    onChange={handleFileChange}
                                    className='hidden'
                                    id="fileInput"
                                />
                                {!imageSrc && (
                                    <label
                                        htmlFor="fileInput"
                                        className="cursor-pointer text-sm text-black"
                                    >
                                        <CiImageOn className="inline-block text-4xl mb-2 text-black" />
                                        <p className="text-lg font-semibold">Upload Image</p>
                                        <p className="text-xs text-gray-500 mt-1">Image size limit: 5MB</p>
                                    </label>
                                )}
                            </div>
                            {imageSrc && (
                                <div className='w-full h-auto'>
                                    <img
                                        src={imageSrc}
                                        alt="Selected"
                                        className='w-full rounded-lg shadow-md'
                                        style={{ height: 'auto', maxHeight: '400px' }}
                                    />
                                </div>
                            )}
                        </div>

                        {/* Show the country selection list only after the image is uploaded */}
                        {imageSrc && (
                            <div className='mt-5 w-full'>
                                <label htmlFor="countrySelect" className="block text-gray-700 font-medium">
                                    Select Country:
                                </label>
                                <select
                                    id="countrySelect"
                                    value={selectedCountry}
                                    onChange={(e) => setSelectedCountry(e.target.value)}
                                    className="mt-2 block w-full bg-white border border-gray-300 rounded-md p-2"
                                >
                                    {Object.keys(passportSizes).map(country => (
                                        <option key={country} value={country}>
                                            {country}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}

                        {/* Clear and Submit buttons */}
                        {imageSrc && (
                            <div className='w-full mt-5 flex justify-between'>
                                <button onClick={handleClear} className='w-1/2 mr-2 h-10 bg-gray-200 text-gray-800'>
                                    Clear
                                </button>
                                <button onClick={handleSubmit} className='w-1/2 ml-2 h-10 bg-black text-white'>
                                    Submit
                                </button>
                            </div>
                        )}
                        {loading && (
                            <div className='mt-10 text-center'>
                                <p className='text-lg'>Processing Time: {elapsedTime} seconds</p>
                            </div>
                        )}
                    </div>

                    {/* Right-side image that hides when an image is uploaded */}
                    {showRightImage && !imageSrc && (
                        <div className='hidden lg:block lg:w-1/2 lg:pl-10'>
                            <img src={imgg} alt="Right Side Image" className='rounded-lg shadow-md' />
                        </div>
                    )}
                </div>

                {/* Display the enhanced image in a medium-sized box */}
                {enhancedImage && (
                    <div className='mt-10 text-center'>
                        <p className='text-lg font-semibold'>Passport Size Image</p>
                        <div className='w-96 mx-auto'>
                            <img src={enhancedImage} alt="Enhanced" className='w-full rounded-lg shadow-md' />
                        </div>
                        <button onClick={handleDownload} className='mt-5 h-10 w-full bg-black text-white'>
                            Download Image
                        </button>
                    </div>
                )}
            </div>

            <Rtools />
            <Pas1/>
            <ThreePhotoSection/>
        
        <Pas2/>
        <Pas3/>
            <Features />
            <Faqs />
            <Reviews />
            <Footer />
        </div>
    );
}
