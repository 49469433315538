import React from 'react';
import yourImage from '../assets/passport7.webp'; // Update this path to your actual image path

const Pas2 = () => {
    return (
        <div className="bg-gradient-to-r from-blue-200 via-purple-300 to-blue-200 py-10 text-center">
            <div className="flex flex-col lg:flex-row items-center justify-between px-4 py-0 max-w-7xl mx-auto gap-12">
                <div className="relative w-full lg:w-1/2 max-w-3xl mx-auto order-2 lg:order-1">
                    <div className="relative w-full h-0 pb-[56.25%] overflow-hidden rounded-lg shadow-lg">
                        <img
                            src={yourImage}
                            alt="Passport Photo Features"
                            className="absolute top-0 left-0 w-full h-full object-cover"
                        />
                    </div>
                </div>
                <div className="lg:w-1/2 w-full text-left lg:text-left mb-8 lg:mb-0 order-1 lg:order-2">
                    <h4 className="text-4xl font-extrabold text-gray-900 mb-0">Quick & Easy Passport Photo Creation</h4>
                    <p className="text-lg text-gray-600 max-w-2xl mb-0">
                        Creating passport photos has never been easier. Simply upload your image, choose the required passport size, and download the correctly formatted photo in seconds. Our tool guarantees that your photo meets the official specifications, making it perfect for passport applications across different countries. Hassle-free and precise every time.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Pas2;
