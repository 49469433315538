import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/Logo.webp';
import AuthPopup from './AuthPopup';
import { UserContext } from './UserContext';
import usic from '../assets/usic.png';

export default function Navbar() {
  const [isAuthPopupOpen, setIsAuthPopupOpen] = useState(false);
  const [isAiPoweredToolsOpen, setIsAiPoweredToolsOpen] = useState(false);
  const [isAiEditingToolsOpen, setIsAiEditingToolsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { user, setUser } = useContext(UserContext);

  const openAuthPopup = () => {
    setIsAuthPopupOpen(true);
  };

  const closeAuthPopup = () => {
    setIsAuthPopupOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSignOut = () => {
    setUser(null);
    localStorage.removeItem('user');
  };

  return (
    <>
      <nav className="bg-gradient-to-r from-blue-200 via-purple-300 to-blue-200 fixed w-full z-[111] top-0 left-0 border-b border-gray-200 dark:border-gray-600">
        <div className="max-w-screen-xl flex items-center justify-between mx-auto p-4">
          <Link to="/">
            <img src={logo} alt="Logo" className="h-10 w-auto" />
          </Link>
          <div className="hidden md:flex justify-center flex-1 space-x-8">
            {/* AI Powered Tools Dropdown */}
            <div
              className="relative group"
              onMouseEnter={() => setIsAiPoweredToolsOpen(true)}
              onMouseLeave={() => setIsAiPoweredToolsOpen(false)}
            >
              <button className="text-gray-700 hover:text-gray-900 focus:outline-none">
                AI Powered Tools {isAiPoweredToolsOpen ? "▲" : "▼"}
              </button>
              {isAiPoweredToolsOpen && (
                <div className="absolute top-full left-0 mt-0 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-[113]">
                  <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <Link to="/imgenhance" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      Photo Enhancer & Upscaler
                    </Link>
                    <Link to="/bgrm" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      Ai Background Remover
                    </Link>
                    <Link to="/blurbg" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      Ai Blur Background
                    </Link>
                    <Link to="/retouch" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      Photo Retouch
                    </Link>
                  </div>
                </div>
              )}
            </div>

            {/* AI Editing Tools Dropdown */}
            <div
              className="relative group"
              onMouseEnter={() => setIsAiEditingToolsOpen(true)}
              onMouseLeave={() => setIsAiEditingToolsOpen(false)}
            >
              <button className="text-gray-700 hover:text-gray-900 focus:outline-none">
                AI Editing Tools {isAiEditingToolsOpen ? "▲" : "▼"}
              </button>
              {isAiEditingToolsOpen && (
                <div className="absolute top-full left-0 mt-0 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-[113]">
                  <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <Link to="/bgvideo" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      Ai Background Video
                    </Link>
                    <Link to="/facecut" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      Face Cutout
                    </Link>
                    <Link to="/passport" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      Passport Photo Maker
                    </Link>
                    <Link to="/imgconvert" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      Image Converter
                    </Link>
                  </div>
                </div>
              )}
            </div>

            <Link to="/blog" className="text-gray-700 hover:text-gray-900">
              Blog
            </Link>
          </div>

          <div className="flex items-center space-x-3">
            <div className="md:hidden">
              <button
                onClick={toggleMenu}
                className="text-gray-700 hover:text-gray-900 focus:outline-none"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
                  ></path>
                </svg>
              </button>
            </div>

            {user ? (
              <div className="relative hidden md:block">
                <button onClick={toggleMenu} className="flex items-center focus:outline-none">
                  <img src={usic} alt="User Icon" className="w-6 h-6" />
                  <svg
                    className="w-4 h-4 ml-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
                {isMenuOpen && (
                  <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-[113]">
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                      <div className="px-4 py-2 text-sm text-gray-700">User Email: {user.email}</div>
                      <div className="px-4 py-2 text-sm text-gray-700">Image Credits: {user.imageCredits}</div>
                      <div className="px-4 py-2 text-sm text-gray-700">Video Credits: {user.videoCredits}</div>
                      <div className="block px-4 py-2 text-sm text-blue-500 hover:bg-gray-100 cursor-pointer">
                        Buy Credits or Subscribe
                      </div>
                      <div onClick={handleSignOut} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer">
                        Sign out
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <button
                type="button"
                onClick={openAuthPopup}
                className="hidden md:inline-block text-black border border-black hover:bg-black hover:text-white focus:ring-4 focus:outline-none focus:ring-pink-300 font-medium rounded-lg text-sm px-4 py-2 text-center"
              >
                Log in / Sign up
              </button>
            )}
          </div>
        </div>

        {isMenuOpen && (
          <div className="md:hidden absolute right-0 top-full mt-2 w-64 max-h-[80vh] overflow-y-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-[113]">
            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <Link to="/blog" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                Blog
              </Link>
              {/* AI Powered Tools Dropdown for Mobile */}
              <div>
                <button
                  onClick={() => setIsAiPoweredToolsOpen(!isAiPoweredToolsOpen)}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  AI Powered Tools {isAiPoweredToolsOpen ? "▲" : "▼"}
                </button>
                {isAiPoweredToolsOpen && (
                  <div className="pl-4">
                    <Link to="/imgenhance" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      Photo Enhancer & Upscaler
                    </Link>
                    <Link to="/background-changer" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      Ai Background Changer
                    </Link>
                    <Link to="/blurbg" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      Ai Blur Background
                    </Link>
                    <Link to="/retouch" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      Photo Retouch
                    </Link>
                  </div>
                )}
              </div>
              {/* AI Editing Tools Dropdown for Mobile */}
              <div>
                <button
                  onClick={() => setIsAiEditingToolsOpen(!isAiEditingToolsOpen)}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  AI Editing Tools {isAiEditingToolsOpen ? "▲" : "▼"}
                </button>
                {isAiEditingToolsOpen && (
                  <div className="pl-4">
                    <Link to="/bgvideo" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      Ai Background Video
                    </Link>
                    <Link to="/facecut" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      Face Cutout
                    </Link>
                    <Link to="/passport" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      Passport Photo Maker
                    </Link>
                    <Link to="/imgconvert" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      Image Converter
                    </Link>
                  </div>
                )}
              </div>
              {user ? (
                <>
                  <div className="px-4 py-2 text-sm text-gray-700">
                    User Email: {user.email}
                  </div>
                  <div className="px-4 py-2 text-sm text-gray-700">
                    Image Credits: {user.imageCredits}
                  </div>
                  <div className="px-4 py-2 text-sm text-gray-700">
                    Video Credits: {user.videoCredits}
                  </div>
                  <div className="block px-4 py-2 text-sm text-blue-500 hover:bg-gray-100 cursor-pointer">
                    Buy Credits or Subscribe
                  </div>
                  <div
                    onClick={handleSignOut}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                  >
                    Sign out
                  </div>
                </>
              ) : (
                <button
                  type="button"
                  onClick={openAuthPopup}
                  className="block w-full text-left px-4 py-2 text-sm text-black border border-black hover:bg-black hover:text-white focus:ring-4 focus:outline-none focus:ring-pink-300 font-medium rounded-lg"
                >
                  Log in / Sign up
                </button>
              )}
            </div>
          </div>
        )}
      </nav>

      {isAuthPopupOpen && <AuthPopup onClose={closeAuthPopup} />}
    </>
  );
}
