import React from 'react';
import { useLocation } from 'react-router-dom';
import { CiImageOn } from 'react-icons/ci';
import Navbar from './Navbar';

const OutputComponent = () => {
    const location = useLocation();
    const { loading, enhancedImage } = location.state || {};

    return (
        <div className="min-h-screen bg-gradient-to-b from-blue-200 to-yellow-100 p-[1px]">
            <Navbar />
            <h2 className="text-10xl md:text-3xl text-center sm:text-2xl font-bold mt-20">
                Image Enhancer & Image Upscaler
            </h2>
            <p className="text-lg md:text-base sm:text-sm text-center text-gray-600 mt-5">
                Effortlessly enhance and upscale your photo to get higher resolution image with one single click.
            </p>
            <div className='w-[90%] lg:w-[60%] md:w-[80%] sm:w-[95%] m-auto mt-[40px] h-auto pb-[20px] bg-[#F9FAFB]'>
                <div className='w-[97%] h-[300px] shad mt-[10px] rounded-[10px] border-dotted border-2 border-[#E5E7EB] m-auto bg-white relative'>
                    <div className='w-[175px] round h-[25px] border text-[12px] flex justify-center items-center gap-[7px] text-[#6B88B5]'>
                        <CiImageOn />
                        Output (The Whole image)
                    </div>
                    <div className='w-[100%] h-[100%] absolute top-0 left-0 flex justify-center items-center p-[1px]'>
                        <div className='w-[40%] h-[40%] text-center text-[15px] pt-[10px] text-[#6B7280] flex justify-center items-center'>
                            {loading ? (
                                <div className='flex flex-col justify-center items-center'>
                                    <div className="w-16 h-16 border-4 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
                                    <p className='mt-2 text-sm text-gray-600'>Processing...</p>
                                </div>
                            ) : enhancedImage ? (
                                <div className='w-full flex flex-col items-center'>
                                    <img
                                        src={enhancedImage}
                                        alt="Enhanced"
                                        className='h-auto w-full object-cover'
                                    />
                                </div>
                            ) : (
                                <p className='text-sm text-gray-600'>Wait a minute your img in processing.</p>
                            )}
                        </div>
                    </div>
                </div>
                {enhancedImage && (
                    <a
                        href={enhancedImage}
                        download='enhanced-image.png'
                        className='w-[80%] ml-[10%] py-2 px-6 mt-[10px] bg-blue-500 text-white text-center rounded block'
                    >
                        Download Here!
                    </a>
                )}
                <div className='w-[100%] flex flex-col lg:flex-row justify-around mt-[10px]'>
                    <div className='w-full lg:w-[45%] h-auto pb-[20px] bg-white rounded-[10px] mb-4 lg:mb-0'>
                        <div className='w-full h-[230px] bg-red-400'>
                            <img className='w-full h-full rounded-t-[10px] object-cover' src="https://images.unsplash.com/photo-1514540344452-642d604143a2?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGJsdXJyeXxlbnwwfHwwfHx8MA%3D%3D" alt="" />
                        </div>
                        <h2 className='text-[20px] font-medium text-center mt-[10px]'>How to Reduce Noise from Photo [2024]</h2>
                    </div>
                    <div className='w-full lg:w-[45%] h-auto pb-[20px] bg-white rounded-[10px]'>
                        <div className='w-full h-[230px] bg-red-400'>
                            <img className='w-full h-full rounded-t-[10px] object-cover' src="https://plus.unsplash.com/premium_photo-1720970650017-e39447272615?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDF8fHNjcmVlbnNob3R8ZW58MHx8MHx8fDA%3D" alt="" />
                        </div>
                        <h2 className='text-[20px] font-medium text-center mt-[10px]'>Improve Screenshot Quality: Tips and Techniques for Enhanced Visuals</h2>
                    </div>
                </div>
                <div className='w-[100%] h-auto p-[1px] pb-[10px]'>
                    
                    
                    
                    <div className='w-auto m-auto mt-[20px] flex justify-center flex-col sm:flex-row items-center'>
                        
                        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OutputComponent;
